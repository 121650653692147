@charset "utf-8";

/*-------------Import fonts ---------------------------------------*/
@import "fonts";

/*-------------General Style---------------------------------------*/
html {
    height: 100%;
    overflow-x: hidden !important;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #000;
    background: #1B1B1B;
    height: 100%;
    text-align: center;
}

/*--------------Typography--------------------------------------*/
h1 {
    font-size: 4.46666666667em;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 1.17em;
}

h2 {
    font-size: 3.2em;
    font-weight: 300;
}

h3 {
    font-size: 1em;
}

h4 {
    font-size: 0.9em;
    font-weight: 200;
}

h5 {
    font-size: 0.8em;
    font-weight: 200;
}

h6 {
    font-size: 0.6em;
    font-weight: 200;
}

p {
    text-align: left;
    font-size: 12px;
    line-height: 24px;
    padding-bottom: 10px;
}

blockquote {
    float: left;
    padding: 10px 20px;
    margin: 0 0 20px;
    font-size: 17.5px;
    border-left: 15px solid #eee;
}

blockquote p {
    font-family: Georgia, "Times New Roman", Times, serif;
    font-style: italic;
    color: #494949;
}

/* ---------Page preload--------------------------------------*/
.loader {
    position: fixed;
    top: 50% !important;
    left: 50%;
    width: 105px;
    height: 8px;
    z-index: 100;
    font-size: 34px;
    opacity: 0.8;
    margin: -4px 0 0 -53px;
}

#movingBallG {
    position: relative;
    width: 105px;
    height: 8px;
}

.movingBallLineG {
    position: absolute;
    left: 0;
    top: 3px;
    height: 1px;
    width: 105px;
    background: rgba(255, 255, 255, 0.21);
}

.movingBallG {
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 8px;
    -moz-border-radius: 4px;
    -moz-animation-name: bounce_movingBallG;
    -moz-animation-duration: 1.3s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-direction: normal;
    -webkit-border-radius: 4px;
    -webkit-animation-name: bounce_movingBallG;
    -webkit-animation-duration: 1.3s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-direction: normal;
    -ms-border-radius: 4px;
    -ms-animation-name: bounce_movingBallG;
    -ms-animation-duration: 1.3s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-direction: normal;
    -o-border-radius: 4px;
    -o-animation-name: bounce_movingBallG;
    -o-animation-duration: 1.3s;
    -o-animation-iteration-count: infinite;
    -o-animation-direction: normal;
    border-radius: 4px;
    animation-name: bounce_movingBallG;
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
    animation-direction: normal;
}

@-moz-keyframes bounce_movingBallG {
    0% {
        left: 0;
    }
    50% {
        left: 97px;
    }
    100% {
        left: 0;
    }
}

@-webkit-keyframes bounce_movingBallG {
    0% {
        left: 0;
    }
    50% {
        left: 97px;
    }
    100% {
        left: 0;
    }
}

@-ms-keyframes bounce_movingBallG {
    0% {
        left: 0;
    }
    50% {
        left: 97px;
    }
    100% {
        left: 0;
    }
}

@-o-keyframes bounce_movingBallG {
    0% {
        left: 0;
    }
    50% {
        left: 97px;
    }
    100% {
        left: 0;
    }
}

@keyframes bounce_movingBallG {
    0% {
        left: 0;
    }
    50% {
        left: 97px;
    }
    100% {
        left: 0;
    }
}

/* ---------Content Styles--------------------------------------*/
#main {
    height: 100%;
    width: 100%;
    position: absolute;
    float: left;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: 0;
}

#wrapper {
    height: 100%;
    margin-left: 0;
    vertical-align: top;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 2;
    width: 100%;
}

.content-holder {
    vertical-align: top;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 2;
    height: 100%;
    padding: 0 0 0 80px;
    -webkit-transition: all 400ms linear;
    -moz-transition: all 400ms linear;
    -o-transition: all 400ms linear;
    -ms-transition: all 400ms linear;
    transition: all 400ms linear;
}

.container {
    max-width: 1224px;
    width: 92%;
    margin: 0 auto;
    position: relative;
    z-index: 2;
}

.content {
    width: 100%;
    position: relative;
    z-index: 3;
    float: left;
    background: #fff;
    -webkit-transform: translate3d(0, 0, 0);
}

.pad-con {
    padding-top: 80px;
}

.content:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    height: 100%;
    z-index: 20;
    width: 7px;
    left: 42px;
    background-repeat: repeat-y;
    background-image: url(/images/bg-dec.png);
    opacity: 0.5;
}

.no-bg-con:before {
    display: none;
}

.dark-bg {
    background: #1B1B1B;
}

.dark-bg .overlay {
    opacity: 0.7;
}

.full-height {
    height: 100%;
}

.parallax-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
}

.parallax-inner .bg {
    height: 150%;
    top: -25%;
}

.bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-attachment: scroll;
    background-position: center;
    background-repeat: repeat;
    background-origin: content-box;
}

.full-height-parallax {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 2;
}

.respimg {
    width: 100%;
    height: auto;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.2;
}

section {
    float: left;
    width: 100%;
    position: relative;
    padding: 120px 0;
    overflow: hidden;
}

.parallax-section {
    padding: 250px 0;
}

.align-text {
    text-align: left;
}

.no-border {
    border: none !important;
}

.no-padding {
    padding: 0 !important;
}

.btn {
    float: left;
    padding: 12px 23px;
    line-height: 17px;
    font-weight: 700;
    font-size: 11px;
    color: #000;
    letter-spacing: 1px;
    -webkit-transition: all 400ms linear;
    -moz-transition: all 400ms linear;
    -o-transition: all 400ms linear;
    -ms-transition: all 400ms linear;
    transition: all 400ms linear;
    border: 1px solid #000;
}

.btn:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: #000;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    transition: all 200ms linear;
    z-index: -1;
    -webkit-transition-delay: 200ms; /* Safari */
    transition-delay: 200ms;
}

.btn:hover:before {
    width: 100%;
}

.btn:hover {
    color: #fff;
}

.anim-button {
    overflow: hidden;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    vertical-align: middle;
    max-width: 100%;
    outline: none;
    width: auto;
}

.anim-button i {
    top: -100%;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    line-height: 40px;
    text-align: center !important;
    font-size: 16px;
}

.wt-btn {
    color: #fff;
    border: 2px solid #fff;
}

.anim-button i, .anim-button span {
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    transition: all 200ms linear;
}

.anim-button span {
    float: left;
    width: 100%;
    position: relative;
    top: 0;
}

.anim-button:hover span {
    top: 100px;
}

.anim-button:hover i {
    top: 0;
}

.custom-link-holder {
    float: left;
    width: 100%;
    position: relative;
    margin-top: 50px;
    padding-top: 50px;
}

.custom-link-holder:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 1px;
    background: #000;
}

.custom-link-holder a {
    float: none;
    display: inline-table;
}

.parallax-item, .paralax-media {
    float: left;
    width: 100%;
    position: relative;
}

.parallax-item {
    margin-bottom: 70px;
}

.parallax-deck {
    position: absolute;
    bottom: 0;
    padding: 50px;
    z-index: 3;
}

.left-direction .parallax-deck {
    right: -30%;
}

.right-direction .parallax-deck {
    left: -30%;
}

.parallax-deck-item {
    float: left;
    max-width: 550px;
}

.paralax-wrap {
    float: left;
    width: 100%;
    opacity: 0.6;
    padding-top: 50px;
}

.parallax-deck-item h3 {
    font-size: 50px;
    line-height: 64px;
    text-transform: uppercase;
    float: left;
    text-align: left;
    width: 100%;
    color: #000;
    font-weight: 300;
    position: relative;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 40px;
}

.parallax-deck-item h3:before {
    content: '';
    position: absolute;
    top: -50px;
    left: 0;
    width: 50px;
    height: 1px;
    background: #000;
}

.parallax-deck-item h3 strong {
    font-weight: bold;
}

.parallax-box {
    position: relative;
    left: -20%;
    z-index: -1;
    float: left;
    width: 100%;
}

.parallax-box.slider-box {
    z-index: 3;
}

.parallax-box.r-align {
    left: 20%;
}

.parallax-box img {
    opacity: 0.6;
}

.parallax-box.slider-box img {
    opacity: 1;
}

.parallax-dec {
    position: absolute;
    top: -30px;
    right: -30px;
    width: 100%;
    height: 100%;
    border: 2px solid #000;
    opacity: 0.8;
    z-index: -1;
}

.section-title {
    font-size: 40px;
    line-height: 64px;
    text-transform: uppercase;
    float: left;
    margin-bottom: 30px;
    text-align: left;
    width: 100%;
    color: #000;
    font-weight: 100;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.section-title.dec-title {
    padding-bottom: 50px;
}

.section-title.dec-title span {
    position: relative;
}

.section-title.dec-title span:before {
    content: '';
    position: absolute;
    bottom: -50px;
    left: 0;
    width: 80px;
    height: 8px;
    background: #000;
}

.algn-right {
    text-align: right;
}

.dark-bg .section-title, .dark-bg {
    color: #fff;
}

.section-title strong {
    font-weight: bold;
}

.particular {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    display: none;
}

/* ---------responsive iframes--------------------------------------*/
.resp-video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    margin-bottom: 20px;
}

.resp-video iframe,
.resp-video object,
.resp-video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/*------item hover ------------------------------------------------*/
.box-item {
    float: left;
    width: 100%;
}

.box-item a {
    float: left;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.box-item a img, .serv-item img {
    position: relative;
    z-index: 1;
    -webkit-transition: all 4000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    -moz-transition: all 4000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    transition: all 4000ms cubic-bezier(.19, 1, .22, 1) 0ms;
}

.box-item a .overlay {
    opacity: 0;
    z-index: 2;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    transition: all 200ms linear;
}

.box-item a:hover .overlay {
    opacity: 0.3;
}

.box-item a:hover img {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    transform: scale(1.05);
}

/*------ Header  ------------------------------------------------*/
header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    height: 80px;
    width: 70%;
    background: #000;
    -webkit-transform: translate3d(0, 0, 0);
}

.fw-head {
    width: 100%;
}

header:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 50px;
    height: 1px;
    left: 15px;
    background: rgba(255, 255, 255, 0.21);
    -webkit-transition: all 500ms linear;
    -moz-transition: all 500ms linear;
    -o-transition: all 500ms linear;
    -ms-transition: all 500ms linear;
    transition: all 500ms linear;
}

.vis-header-b:before {
    width: 97%;
}

.header-inner {
    float: left;
    padding-left: 80px;
}

.logo-holder {
    float: left;
    position: relative;
    width: 90px;
    z-index: 21;
    top: 20px;
}

.logo-holder img {
    width: 100%;
    height: auto;
}

.dynamic-title {
    display: none;
}

/*------ navigation  ------------------------------------------------*/
.nav-holder {
    float: right;
    position: relative;
    top: 22px;
    left: 50px;
}

nav {
    position: relative;
    float: left;
    width: 100%;
}

nav li {
    float: left;
    position: relative;
}

nav li ul {
    margin: 30px 0 0 0;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    min-width: 190px;
    padding-top: 58px;
    padding-bottom: 20px;
    left: 0;
    z-index: 0;
    background: rgba(0, 0, 0, 0.91);
    transition: all .2s ease-in-out;
}

nav li ul:before {
    content: '';
    position: absolute;
    bottom: 12px;
    left: 10px;
    right: 10px;
    background: rgba(255, 255, 255, 0.21);
    height: 1px;
}

nav li:hover > ul {
    opacity: 1;
    visibility: visible;
    margin: 0;
}

nav li ul li ul {
    top: 0;
    left: 100%;
    padding-top: 10px;
}

nav li ul li:hover > ul {
    opacity: 1;
    visibility: visible;
    left: 100%;
}

nav li ul li {
    float: none;
    display: block;
    border: 0;
}

nav li a {
    float: left;
    padding: 10px 15px;
    line-height: 1;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fff;
    font-family: 'Muli', sans-serif;
    font-weight: 600;
    position: relative;
    z-index: 2;
    -webkit-transition: all 100ms linear;
    -moz-transition: all 100ms linear;
    -o-transition: all 100ms linear;
    -ms-transition: all 100ms linear;
    transition: all 100ms linear;
}

nav li ul a {
    width: 100%;
    text-align: left;
    padding: 9px 12px;
    font-weight: 300;
}

nav li a:before {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 15px;
    width: 0;
    height: 1px;
    background: rgba(255, 255, 255, 0.41);
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}

nav li a.act-link:before {
    width: 25px;
}

nav li ul a:before {
    display: none;
}

nav li ul a.act-link {
    color: #000;
    font-weight: 600;
}

nav li a:hover {
    color: rgba(255, 255, 255, 0.61);
}

/*------ Home -------------------------------------------------*/
.hero-wrap {
    float: left;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    z-index: 2;
    -webkit-transform: translate3d(0, 0, 0);
}

.hero-wrap .overlay {
    z-index: 2;
}

.hero-wrap-item {
    float: left;
    width: 100%;
    position: relative;
    z-index: 12;
}

.center-item {
    top: 50%;
}

.nFound-page-wrap {
    top: 36%;
}

.hero-wrap-item h2 {
    color: #fff;
    font-size: 38px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
    font-family: 'Muli', sans-serif;
    padding-bottom: 10px;
    position: relative;
    z-index: 12;
}

.hero-wrap-item h3, .hero-wrap-item h3 a {
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 200;
    letter-spacing: 18px;
    padding-left: 6px;
}

.hero-wrap-item h3 a {
    letter-spacing: 2px;
}

.nFound-Page {
    display: inline-table;
    font-size: 150px;
    text-transform: uppercase;
    color: #fff;
}

.hero-scroll-link {
    position: absolute;
    bottom: 50px;
    left: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #fff;
    font-size: 12px;
    margin-left: -20px;
    z-index: 9;
    border: 1px solid rgba(255, 255, 255, 0.21);
}

.hero-wrap-image-slider-holder, .slideshow-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.hero-wrap-image-slider, .hero-wrap-text-slider-holder, .hero-wrap-text-slider, .hero-wrap-text-slider-holder {
    position: relative;
    width: 100%;
    height: 100%;
    float: left;
}

.hero-wrap-image-slider-holder {
    background: #000;
}

.hero-wrap-text-slider-holder {
    z-index: 3;
}

.hero-link {
    margin-top: 30px;
    display: inline-table;
    padding: 10px 6px;
    color: #fff;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
}

.hero-link:hover {
    color: rgba(255, 255, 255, 0.51)
}

.media-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
}

.video-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.mob-bg {
    display: none;
}

.video-holder {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    overflow: hidden !important;
    top: 0;
    left: 0;
    padding: 0 !important;
}

.video-holder iframe {
    position: absolute;
    top: -75px;
    left: 50%;
    width: 100%;
    height: 100%;
    display: block;
    overflow: hidden;
}

.fullwidth-slider-holder, .single-slider-holder {
    float: left;
    width: 100%;
    position: relative;
}

.fullwidth-slider-holder .item {
    position: relative;
}

.full-width-holder {
    float: left;
    width: 100%;
    position: relative;
}

.single-slider-holder {
    margin-bottom: 20px;
}

.single-slider .item {
    position: relative;
}

article {
    float: left;
    margin-top: 10px;
    width: 100%;
    position: relative;
}

/*------ Page title  ------------------------------------------------*/
.page-title {
    float: left;
    width: 100%;
    position: relative;
    z-index: 5;
}

.page-title .creat-list a:before {
    background: #fff;
}

.page-title .creat-list {
    position: relative;
    top: 20px;
}

.parallax-section .page-title, .parallax-section .creat-list a {
    color: #fff;
}

.page-title h2 {
    font-size: 40px;
    text-transform: uppercase;
    float: left;
    margin-bottom: 30px;
    text-align: left;
    width: 100%;
    color: #fff;
    font-weight: 100;
    text-align: left;
    position: relative;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.page-title h2:before {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 80px;
    height: 4px;
    background: #fff;
}

.parallax-section .page-title {
    color: #fff;
}

.page-title h2 strong {
    font-weight: bold;
}

/*------ services  ------------------------------------------------*/
.services-holder {
    float: left;
    width: 100%;
    position: relative;
    margin-top: 60px;
    padding-bottom: 40px;
    border-bottom: 1px solid #eee;
}

.serv-item {
    float: left;
    width: 50%;
    position: relative;
    padding: 10px 20px 10px 0;
    overflow: hidden;
}

.serv-item img {
    position: relative;
    width: 100%;
    height: auto;
}

.serv-item-inner {
    float: left;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.serv-item-inner:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.21);
    z-index: 2;
}

.act-ser .serv-item-inner:before {
    background: rgba(0, 0, 0, 0.41);
}

.ser-title {
    position: absolute;
    bottom: 20px;
    z-index: 3;
    width: 100%;
    left: 0;
}

.act-ser .ser-title {
    bottom: 50px;
}

.ser-title h3 {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    position: relative;
    display: inline-table;
}

.ser-title h3:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: #fff;
    top: -6px;
    left: 0;
    background: #fff;
}

.act-ser .ser-title h3:before {
    width: 0;
}

.serv-post {
    float: left;
    width: 100%;
    position: relative;
    margin-top: 30px;
}

.serv-details {
    float: left;
    width: 100%;
    position: relative;
    display: none;
    text-align: left;
}

.serv-details h3 {
    font-size: 15px;
    text-transform: uppercase;
    font-family: 'Muli', sans-serif;
    font-weight: 600;
    padding-bottom: 10px;
}

.ser-list {
    list-style: circle;
    float: left;
    width: 100%;
    padding: 10px 0 10px 14px;
}

.ser-list li {
    width: 100%;
    float: left;
    margin-bottom: 4px;
    font-family: 'Muli', sans-serif;
    color: #000;
    padding-left: 10px;
    font-weight: 600;
    font-size: 11px;
    letter-spacing: 1px;
}

.price {
    float: left;
    margin-top: 10px;
    padding: 10px 14px;
    border: 2px solid #000;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 2px;
    background: #f9f9f9;
}

.serv-item:hover img {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    transform: scale(1.05);
}

/*------ Porfolio  ------------------------------------------------*/
.gallery-items {
    float: left;
    width: 100%;
    background: #fff;
    position: relative;
}

.gallery-items a {
    cursor: pointer;
}

.grid-item-holder {
    float: left;
    width: 100%;
    height: auto;
    position: relative;
    padding: 2px;
}

.gallery-item, .grid-sizer {
    width: 25%;
    position: relative;
    overflow: hidden;
    float: left;
}

.gallery-item-second,
.grid-sizer-second {
    width: 50%;
}

.gallery-item-three,
.grid-sizer-three {
    width: 75%;
}

.three-columns .gallery-item {
    width: 33.3%;
}

.three-columns .gallery-item-second,
.three-columns .grid-sizer-second {
    width: 66.6%;
}

.five-columns .gallery-item {
    width: 20%;
}

.five-columns .gallery-item-second,
.five-columns.grid-sizer-second {
    width: 40%;
}

.gallery-item img, .grid-sizer img {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 2;
    transform: translateZ(0);
}

.four-coulms .gallery-item, .four-coulms .grid-sizer {
    width: 25%;
}

.three-coulms .gallery-item, .three-coulms .grid-sizer {
    width: 33.33333%;
}

.three-coulms .gallery-item-second {
    width: 66.66666%;
}

.port-desc-holder {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 2px;
    right: 0;
    opacity: 0;
    z-index: 22;
    overflow: hidden;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}

.port-desc-holder:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 3px;
    bottom: 0;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.75))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%); /* IE10+ */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0); /* IE6-9 */
}

.horizontal_item .port-desc-holder:before {
    opacity: 0.4;
}

.horizontal_item .port-desc {
    padding-bottom: 30px;
}

.horizontal_item .port-desc h3 {
    text-transform: none;
    font-size: 22px;
}

.gallery-item .port-desc-holder:before {
    right: 3px;
    bottom: 3px;
}

.port-desc {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 5;
}

.portfolio_item:hover .port-desc-holder, .gallery-item:hover .port-desc-holder {
    opacity: 1;
}

.port-desc-holder .grid-item h3:hover {
    opacity: 0.8;
}

.grid-item {
    float: left;
    width: 100%;
    padding: 30px;
    overflow: hidden;
}

.grid-item h3 {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 400;
    position: relative;
    float: left;
    width: 100%;
    color: #fff;
    text-align: left;
    letter-spacing: 1px;
    z-index: 20;
}

.grid-item h3 a {
    color: #fff;
}

.grid-item span {
    width: 100%;
    float: left;
    font-size: 10px;
    margin-top: 10px;
    color: #fff;
    position: relative;
    text-align: left;
    text-transform: uppercase;
}

.grid-item h3, .grid-item span {
    opacity: 0;
}

.inline-filter {
    float: left;
    width: 100%;
    position: relative;
    padding-bottom: 50px;
}

.inline-filter .gallery-filters {
    float: left;
}

.inline-filter .gallery-filters a {
    float: left;
    padding-left: 30px;
    font-size: 10px;
    font-family: 'Muli', sans-serif;
    line-height: 12px;
    text-transform: uppercase;
    padding-left: 63px;
    position: relative;
    font-weight: 700;
    letter-spacing: 2px;
    color: #000;
    margin-right: 15px;
}

.inline-filter .gallery-filters a:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 40px;
    height: 1px;
    background: #000;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    transition: all 200ms linear;
}

.inline-filter .gallery-filters a.gallery-filter_active:before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.inline-filter .count-folio {
    float: right;
    color: #000;
}

.inline-filter .count-folio div {
    display: inline-table;
    padding-left: 15px;
    font-family: 'Muli', sans-serif;
    font-size: 12px;
    position: relative;
    font-weight: 500;
    text-transform: uppercase;
}

.inline-filter .count-folio div:before {
    content: '/';
    position: absolute;
    right: 20px;
    top: -2px;
    color: #ccc;
    font-size: 16px;
}

.inline-filter .count-folio div:first-child:before {
    display: none;
}

.round-filter {
    position: fixed;
    z-index: 130;
    bottom: 30px;
    width: 250px;
    right: 5px;
    background: #000;
    padding: 15px 19px;
}

.round-filter:before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.21);
}

.ver-filter {
    bottom: 0;
}

.tcf {
    bottom: 32px;
}

.filter-button {
    float: left;
    position: relative;
    color: #fff;
    padding: 5px 15px;
    border: 1px solid rgba(255, 255, 255, 0.21);
    font-size: 10px;
    font-family: 'Muli', sans-serif;
    font-weight: 600;
    letter-spacing: 1px;
}

.round-counter {
    float: right;
    color: #fff;
    position: relative;
    top: 6px;
}

.round-counter:after {
    content: '';
    position: absolute;
    left: -40px;
    top: 50%;
    width: 30px;
    height: 1px;
    background: rgba(255, 255, 255, 0.41);
}

.round-counter:before {
    content: '/';
    position: absolute;
    left: 50%;
    top: -2px;
    color: #fff;
    font-size: 16px;
    margin-left: -4px;
}

.round-counter div {
    float: left;
    margin: 0 10px;
    font-size: 10px;
    color: #fff;
    font-family: 'Muli', sans-serif;
}

.hid-filter {
    float: left;
    position: relative;
    display: none;
    padding-bottom: 20px;
}

.hid-filter a {
    float: left;
    color: #ccc;
    width: 100%;
    font-size: 12px;
    position: relative;
    padding: 4px 10px 4px 0;
    text-align: left;
}

.hid-filter a.gallery-filter_active {
    color: #fff;
}

.float-filters {
    float: left;
    position: relative;
    top: 15px;
}

.p_horizontal_wrap {
    float: left;
    width: 100%;
    position: relative;
    background: #fff;
    height: 100%;
    top: 0;
}

.hpw {
    padding: 0 5px;
}

.visfilb {
    opacity: 0.6;
}

.pr-wrap {
    float: left;
    width: 100%;
    position: relative;
}

#portfolio_horizontal_container {
    height: 100%;
    float: left;
    width: 100%;
    position: relative;
}

#portfolio_horizontal_container .portfolio_item {
    width: auto;
    height: 100%;
    float: left;
    padding: 6px 3px;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

#portfolio_horizontal_container.no-padding .portfolio_item {
    padding: 0
}

#portfolio_horizontal_container.two-ver-columns .portfolio_item {
    height: 50%;
}

#portfolio_horizontal_container.three-ver-columns .portfolio_item {
    height: 33.3%;
}

#portfolio_horizontal_container .portfolio_item img {
    float: left;
    width: auto;
    height: 100%;
    position: relative;
    z-index: 1;

}

.no-bg {
    background: none !important
}

.vis-port-info .grid-item:before, .vis-port-info .grid-item:after {
    display: none;
}

.vis-port-info .grid-item h3, .vis-port-info .grid-item span {
    opacity: 1;
}

.grid-item-holder:hover .grid-item h3, .grid-item-holder:hover .grid-item span, .port-desc-holder:hover .grid-item span, .port-desc-holder:hover .grid-item h3, .horizontal_item:hover .grid-item span, .horizontal_item:hover .grid-item h3 {
    opacity: 1;
}

.portfolio_item:hover .grid-item span {
    opacity: 0.7;
}

.st-3 .grid-item-holder a img, .gallery-item img, .grid-sizer img, .grid-item h3, .grid-item span, .ser-title h3:before, .ser-title {
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}

.box-gal .col-md-6 {
    padding-bottom: 25px;
    cursor: pointer;
}

.full-screen-gallery-holder {
    position: relative;
    width: 100%;
    float: left;
}

.full-screen-item {
    position: relative;
}

.customNavigation a,
.customNavigation span {
    position: absolute;
    top: 50%;
    margin-top: -20px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: rgba(0, 0, 0, 0.31);
    text-align: center;
    cursor: pointer;
    color: #fff;
    font-size: 13px;
    opacity: 1;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    transition: all 200ms linear;
    z-index: 20
}

.customNavigation a.next-slide,
.customNavigation span.next-slide {
    right: 50px;
}

.customNavigation a.prev-slide,
.customNavigation span.prev-slide {
    left: 50px;
}

.customNavigation.ssn a.next-slide {
    right: 10px;
}

.customNavigation.ssn a.prev-slide {
    left: 10px;
}

.customNavigation a:hover,
.customNavigation span:hover {
    opacity: 1;
}

.customNavigation a i,
.customNavigation span i {
    -webkit-transition: all 500ms linear;
    -moz-transition: all 500ms linear;
    -o-transition: all 500ms linear;
    -ms-transition: all 500ms linear;
    transition: all 500ms linear;
}

.customNavigation a:hover i,
.customNavigation span:hover i {
    -webkit-transform: rotateX(360deg);
    -moz-transform: rotateX(360deg);
    -o-transform: rotateX(360deg);
    -ms-transform: rotateX(360deg);
    transform: rotateX(360deg);
}

.slider-zoom {
    position: absolute;
    top: 50px;
    right: 50px;
    z-index: 10;
    width: 35px;
    height: 35px;
    color: #fff;
    background: rgba(0, 0, 0, 0.11);
    cursor: pointer;
    line-height: 40px;
    font-size: 14px;
    text-align: center;
}

.slider-zoom i, .slider-zoom:before, .slider-zoom:after, .slider-zoom, .herolink {
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    transition: all 200ms linear;
}

.slider-zoom:before, .slider-zoom:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    z-index: -1;
}

.slider-zoom:before {
    top: 0;
    left: 0;
    border-left: 1px solid #fff;
    border-top: 1px solid #fff;
}

.slider-zoom:after {
    bottom: 0;
    right: 0;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
}

.slider-zoom:hover:before {
    top: -5px;
    left: -5px;
}

.slider-zoom:hover:after {
    bottom: -5px;
    right: -5px;
}

.slider-zoom:hover i {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.nicescroll-rails {
    height: 30px !important;
}

.nicescroll-rails-hr:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: repeat-x center url(/images/bg-dec2.png);
}

.nicescroll-cursors {
    top: 10px !important;
}

.caption {
    float: left;
    position: relative;
    padding-left: 40px;
}

.caption.cap-column {
    margin-left: 62px;
}

.caption:before {
    content: "";
    position: absolute;
    top: 55%;
    left: -40px;
    width: 50px;
    height: 1px;
    background: #000;
}

.caption h4 {
    float: left;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding-bottom: 6px;
    font-size: 14px;
    width: 100%;
    text-align: left;
    color: #000;
    font-weight: 700;
    margin-top: 20px;
}

.caption p {
    color: #999;
    text-transform: uppercase;
    font-size: 10px;
}

.caption.fixed-caption {
    position: absolute;
    bottom: 150px;
    left: 150px;
    z-index: 40;
}

.fix-con-panel {
    position: fixed;
    bottom: 50px;
    right: 30px;
    background: #000;
    border-radius: 4px;
    padding: 12px 20px;
    z-index: 20;
    color: #fff;
}

.hid-con-panel {
    float: left;
    cursor: pointer;
}

.fix-con-panel a, .fix-con-panel span, .vis-con-panel {
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}

.fix-con-panel a:hover, .fix-con-panel span:hover {
    opacity: 0.6;
}

.fix-con-panel a, .fix-con-panel span {
    float: left;
    margin-left: 30px;
    font-size: 10px;
    text-transform: uppercase;
    color: #fff;
    position: relative;
    padding-left: 20px;
    border: 1px solid rgba(255, 255, 255, 0.21);
    padding: 6px 10px;
}

.fix-con-panel span {
    margin-left: 0;
    margin-right: 10px;
}

.fix-con-panel a:before {
    content: '/';
    position: absolute;
    top: 5px;
    left: -20px;
    color: #ccc;
}

.control-panel {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #fff;
    padding: 0 30px 0 32px;
    height: 100px;
    z-index: 19;
}

.control-panel:before {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 100%;
    height: 6px;
    background: repeat-x center url(/images/bg-dec2.png);
}

.control-panel .btn {
    float: right;
    margin-top: 26px;
}

.vis-con-panel {
    float: right;
    margin-right: 40px;
    padding: 14px 26px;
    border: 1px solid #000;
    background: #000;
    top: 26px;
    color: #fff;
    position: relative;
    cursor: pointer;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}

.vis-con-panel.dark-btn {
    color: #fff;
    background: #000;
    border: 0;
    font-size: 11px;
}

.vis-con-panel:hover {
    border-color: #ccc;
}

.vis-con-panel.dark-btn:hover {
    color: rgba(153, 153, 153, 0.61);
}

.vis-con-panel:before {
    content: '/';
    color: #000;
    position: absolute;
    font-size: 16px;
    right: -26px;
    top: 8px;
}

.vis-con-panel.dark-btn:before {
    display: none;
}

.owl-thumbs {
    position: fixed;
    top: 60px;
    left: 0;
    bottom: 60px;
    width: 100%;
    z-index: 30;
    background: #fff;
    visibility: hidden;
    padding: 90px 200px 200px 200px;
    opacity: 0;
    overflow: auto;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}

.fhgal .owl-thumbs {
    bottom: 0;
}

.fhgal {
    padding-left: 10px;
}

.vis-thumbs {
    opacity: 1;
    visibility: visible;
}

.owl-thumb-item {
    float: left;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    transition: all 200ms linear;
    border: none;
    background: none;
    opacity: 1;
    cursor: pointer;
    padding: 10px;
    position: relative;
    opacity: 0;
}

.owl-thumb-item:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    -webkit-transition: all 500ms linear;
    -moz-transition: all 500ms linear;
    -o-transition: all 500ms linear;
    -ms-transition: all 500ms linear;
    transition: all 500ms linear;
    z-index: 2;
}

.himask:before {
    width: 0;
}

.owl-thumb-item.himask {
    opacity: 1;
}

.owl-thumb-item.active {
    opacity: 0.3;
}

.owl-thumb-item img {
    width: auto;
    height: 150px;
}

.gallery_horizontal {
    float: left;
    width: 100%;
    position: relative;
    padding-top: 10px;
}

.gallery_horizontal.fh-wr {
    padding-top: 10px;
}

.gallery_horizontal .owl-item img {
    width: auto;
    position: relative;
    z-index: 1;
}

.horizontal_item {
    overflow: hidden;
    position: relative;
    height: 100%;
}

.gallery_horizontal {
    background: #f9f9f9;
}

.flow-gallery .horizontal_item {
    opacity: 0.6;
    -webkit-transition: all 500ms linear;
    -moz-transition: all 500ms linear;
    -o-transition: all 500ms linear;
    -ms-transition: all 500ms linear;
    transition: all 500ms linear;
    -webkit-transition-delay: 300ms;
    transition-delay: 300ms;
    -webkit-transform: scale(0.65);
    -moz-transform: scale(0.65);
    transform: scale(0.65);
}

.flow-gallery .owl-item.center .horizontal_item {
    opacity: 1;
    -webkit-transform: scale(1.0);
    -moz-transform: scale(1.0);
    transform: scale(1.0);
}

.portfolio_item, .horizontal_item {
    cursor: e-resize;
}

.resize-carousel-holder {
    float: left;
    width: 100%;
    position: relative;
    height: 100%;
}

.fl-l {
    float: left;
}

.flow-gallery:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: 43px;
    width: 100%;
    height: 1px;
    background: #eee;
    opacity: 0.6;
}

.show-info {
    position: absolute;
    bottom: 50px;
    left: 50px;
    width: 80%;
    z-index: 15;
}

.show-info span {
    text-transform: uppercase;
    font-size: 10px;
    color: #fff;
    background: rgba(0, 0, 0, 0.51);
    padding: 6px 12px;
    border: 1px solid rgba(255, 255, 255, 0.21);
    cursor: pointer;
    float: left;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}

.tooltip-info {
    height: auto;
    line-height: 20px;
    padding: 10px 20px;
    font-size: 11px;
    color: #fff;
    text-align: center;
    text-indent: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    opacity: 0;
    left: 0;
    margin-bottom: 40px;
    visibility: hidden;
    pointer-events: none;
    z-index: 16;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}

.tooltip-info p {
    font-size: 11px;
    font-weight: 600;
    white-space: nowrap;
    color: #fff;
}

.tooltip-info h5 {
    font-size: 16px;
    margin-top: 12px;
    padding: 5px 0;
    position: relative;
    font-family: 'Montserrat', sans-serif;
    text-align: left;
}

.show-info:hover .tooltip-info {
    visibility: visible;
    opacity: 1;
    bottom: 0;
}

.sb-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 20;
    opacity: 0;
    visibility: hidden;
    cursor: zoom-out;
}

.sb-overlay, .resize-carousel-holder, .control-panel {
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}

.vis-overlay {
    visibility: visible;
    opacity: 0.5
}

.hid-sidebar {
    position: fixed;
    top: 90px;
    bottom: 0;
    overflow: auto;
    z-index: 22;
    width: 100%;
    opacity: 1;
    right: 0;
    display: none;
}

.vissb {
    display: block;
}

.small-container {
    max-width: 800px;
}

.sidebar-wrap {
    float: left;
    width: 100%;
    position: relative;
    padding: 50px 40px;
    margin-bottom: 50px;
}

.sb-inner {
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
    position: relative;
    opacity: 0;
    visibility: hidden;
    float: left;
    width: 100%;
}

.sb-innervis {
    opacity: 1;
    visibility: visible;
}

.sb-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.scale-callback {
    float: left;
    width: 33.33333%;
    height: 25%;
    position: relative;
    z-index: 5;
}

.scale-callback:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: #fff;
    -webkit-transition: all 400ms cubic-bezier(0.215, .61, .355, 1);
    -moz-transition: all 400ms cubic-bezier(0.215, .61, .355, 1);
    -o-transition: all 400ms cubic-bezier(0.215, .61, .355, 1);
    transition: all 400ms cubic-bezier(0.215, .61, .355, 1);
}

.scale-bg5:before {
    width: 100%;
}

.hid-sidebar h4, .details-container h3, .details-container h4 {
    float: left;
    text-align: left;
    font-size: 16px;
    width: 100%;
    color: #000;
    margin-top: 20px;
    text-transform: uppercase;
}

.hid-sidebar p, .details-container p {
    font-size: 11px;
}

.hid-sidebar h4 {
    font-size: 15px;
    font-family: 'Muli', sans-serif;
    font-weight: 900;
}

.hid-sidebar .separator {
    float: left;
    width: 50px;
    height: 4px;
    background: #000;
    margin: 20px 0 20px;
    position: relative;
}

.pd-holder {
    float: left;
    width: 100%;
    margin-top: 5px;
}

.pd-holder h5 {
    float: left;
    font-size: 10px;
    font-family: 'Muli', sans-serif;
    line-height: 12px;
    text-transform: uppercase;
    position: relative;
    font-weight: 800;
    letter-spacing: 2px;
    color: #999;
}

.pd-holder h5 a {
    font-style: italic;
    opacity: 0.8;
    padding-left: 3px;
}

.project-details {
    float: left;
    width: 100%;
    text-align: left;
    padding-top: 10px;
    margin-bottom: 20px;
}

.project-details li {
    float: left;
    width: 100%;
    margin-bottom: 10px;
    position: relative;
}

.content-nav {
    float: left;
    width: 100%;
    padding: 40px 0 0;
    position: relative;
    margin-top: 40px;
    border-top: 1px solid #eee;
}

.content-nav:before {
    content: '';
    position: absolute;
    top: -4px;
    right: 0;
    width: 8px;
    height: 8px;
    background: #000;
    border-radius: 100%;
}

.content-nav ul {
    position: relative;
    left: 0;
}

.content-nav li {
    float: left;
    width: 50%
}

.close-sidebar {
    position: absolute;
    top: -10px;
    right: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
    overflow: hidden;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 20;
    -webkit-transition: all 400ms linear;
    -moz-transition: all 400ms linear;
    -o-transition: all 400ms linear;
    -ms-transition: all 400ms linear;
    transition: all 400ms linear;
}

.close-sidebar:hover {
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    transform: rotate(225deg);
    opacity: 0.8;
}

.close-sidebar:before, .close-sidebar:after {
    content: '';
    position: absolute;
    background: #000;
}

.close-sidebar:before {
    width: 1px;
    height: 100%;
    top: 1px;
    left: 50%;
}

.close-sidebar:after {
    width: 100%;
    height: 1px;
    top: 50%;
    left: 1px;
}

.full-screen-gallery-holder {
    position: relative;
    width: 100%;
    float: left;
}

.full-screen-item {
    position: relative;
}

.fix-con-panel a.vd {
    margin-left: 0;
}

.fix-con-panel a.vd:before {
    display: none;
}

/* --------- Skills --------------------------------------*/
.piechart-holder, .custom-skillbar-holder {
    padding-top: 50px;
    float: left;
    width: 100%;
    position: relative;
}

.piechart-holder h4 {
    font-size: 1.3em;
    text-transform: uppercase;
}

.chart {
    position: relative;
    display: inline-block;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    margin-bottom: 26px;
    text-align: center;
}

.chart canvas {
    position: absolute;
    top: 0;
    left: 0;
}

.piechart {
    position: relative;
    padding-bottom: 20px;
}

.percent {
    display: inline-block;
    font-size: 21px;
    line-height: 152px;
    z-index: 2;
}

.percent:after {
    content: '%';
    margin-left: 0.1em;
    font-size: .8em;
}

.drk-ch .percent {
    color: #666;
}

.angular {
    margin-top: 100px;
}

.angular .chart {
    margin-top: 0;
}

.skills-description {
    float: left;
    width: 100%;
    margin: 10px 0;
    text-transform: uppercase;
    color: #fff;
    font-size: 12px;
}

.skill-name {
    position: absolute;
    top: 40%;
    text-transform: uppercase;
    font-size: 44px;
    font-weight: 900;
    letter-spacing: 4px;
    opacity: 0.3;
    color: #000;
}

.skill-name span {
    opacity: 0.3;
}

.skill-name-lanim {
    left: 0;
}

.skill-name-ranim {
    right: 0;
}

.skillbar-box {
    float: left;
    width: 100%;
    position: relative;
    margin-bottom: 50px;
}

.skillbar-bg {
    position: relative;
    display: block;
    float: left;
    width: 100%;
    height: 3px;
    overflow: hidden;
    background: #eee;
}

.custom-skillbar-title span {
    float: left;
    font-size: 12px;
    margin-top: 12px;
    padding-bottom: 6px;
}

.custom-skillbar {
    height: 3px;
    width: 0;
    background: #000;
}

.skill-bar-percent {
    float: right;
    font-size: 16px;
    margin-top: 13px;
    padding-bottom: 6px;
}

.clients-list {
    float: left;
    width: 100%;
}

.clients-list a {
    float: left;
    width: 20%;
    padding: 10px 10px 10px 0;
}

.clients-list a:hover {
    opacity: 0.6;
}

.clients-list a img {
    float: left;
    width: 100%;
    height: auto;
}

/*------ Team  ------------------------------------------------*/
.team-holder {
    float: left;
    width: 100%;
}

.team-holder li {
    float: left;
    width: 33.3333%;
    padding: 40px 40px 40px 0;
}

.team-box {
    float: left;
    position: relative;
    width: 100%;
    margin-bottom: 20px;
}

.team-info {
    float: left;
    width: 100%;
    margin-top: 20px;
    text-align: left;
}

.team-info h3 {
    font-size: 19px;
    padding-bottom: 4px;
}

.team-info h4 {
    font-family: 'Muli', sans-serif;
    font-weight: 300;
    font-size: 11px;
}

.team-box:before, .team-box:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    background: #000;
}

.team-box:before {
    width: 0;
    height: 2px;
}

.team-box:after {
    width: 2px;
    height: 0;
}

.team-box:hover:before {
    width: 50px;
}

.team-box:hover:after {
    height: 50px;
}

.team-photo {
    position: relative;
    overflow: hidden;
    width: 100%;
    float: left;
}

.team-box .overlay {
    opacity: 0;
    z-index: 2;
    background: #000;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}

.team-box:hover .overlay {
    opacity: 0.5;
}

.team-social {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    z-index: 3;
    display: none;
}

.team-social li {
    float: none !important;
    display: inline-block !important;
    padding: 6px;
    width: auto !important;
}

.team-social li a {
    color: #fff;
    font-size: 16px;
    position: relative;
    top: 50px;
    opacity: 0;
}

.testimon-slider h3 {
    text-align: left;
    font-size: 26px;
    padding-bottom: 20px;
    text-transform: uppercase;
    font-weight: 200;
}

.testimon-slider .item {
    cursor: e-resize;
}

.testimon-slider a.text-link {
    color: #fff;
    float: left;
    margin-top: 20px;
}

.testimon-slider a.text-link:before {
    background: #fff;
}

.custom-inner-holder {
    float: left;
    margin-top: 60px;
    width: 100%;
}

.custom-inner {
    float: left;
    width: 100%;
    position: relative;
    margin-bottom: 50px;
}

.custom-inner h3 {
    float: left;
    padding-bottom: 20px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 19px;
}

.custom-inner h4 {
    float: left;
    width: 100%;
    padding-bottom: 20px;
    font-weight: 800;
    text-align: left;
    text-transform: uppercase;
}

.custom-inner-dec {
    float: left;
    width: 100%;
    margin-top: 10px;
    background: #eee;
    height: 1px;
}

.custom-inner ul {
    float: left;
    width: 100%;
    padding: 10px 0 10px 13px;
    list-style: decimal;
}

.custom-inner ul li {
    float: left;
    width: 100%;
    padding: 5px 0 5px 10px;
    text-align: left;
}

.custom-inner .content-nav ul {
    position: relative;
    left: 0;
    padding: 0;
    list-style: none;
}

.custom-inner .content-nav ul li {
    float: left;
    width: 50%;
    padding: 0;

}

.custom-inner .btn {
    margin-top: 20px;
}

.testimon-slider-holder {
    float: left;
    width: 100%;
    position: relative;
}

.testimon-slider-holder .customNavigation {
    float: left;
    position: relative;
    width: 100%;
    margin-top: 40px;
}

.testimon-slider-holder .customNavigation a,
.testimon-slider-holder .customNavigation span {
    position: relative;
    top: 0;
    left: 0 !important;
    background: none;
    font-size: 22px;
    float: left;
    margin-right: 16px;
    width: auto;
    height: auto;
}

/*------ footer  ------------------------------------------------*/
footer.fixed-footer {
    position: fixed;
    top: 0;
    left: 0;
    width: 80px;
    height: 100%;
    background: #000;
    z-index: 6;
}

.height-emulator {
    float: left;
    width: 100%;
    position: relative;
    z-index: 1;
}

footer.fixed-footer .footer-social {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
}

footer.fixed-footer .footer-social:before {
    content: '';
    position: absolute;
    top: -20px;
    height: 1px;
    width: 50px;
    left: 15px;
    background: rgba(255, 255, 255, 0.21);
}

footer.fixed-footer .footer-social li {
    float: left;
    width: 100%;
    margin-bottom: 10px;
}

footer.fixed-footer .footer-social li a {
    font-size: 14px;
    color: #fff;
}

footer.fixed-footer .footer-social li a:hover {
    color: rgba(255, 255, 255, 0.51)
}

.content-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 90px 20px 90px 80px;
    background: #1B1B1B;
    z-index: 2;
}

.content-footer:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    height: 100%;
    z-index: 20;
    width: 7px;
    left: 122px;
    background-repeat: repeat-y;
    background-image: url(/images/bg-dec.png);
    opacity: 0.2;
}

.footer-title {
    position: fixed;
    width: 200px;
    top: 280px;
    padding-top: 26px;
    cursor: default;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left top;
    -ms-transform-origin: left top;
    -moz-transform-origin: left top;
    -o-transform-origin: left top;
    transform-origin: left top;
    z-index: 12;
}

.footer-title:before {
    content: '';
    position: absolute;
    bottom: -23px;
    width: 1px;
    height: 50px;
    left: 0;
    background: rgba(255, 255, 255, 0.21);
}

.footer-title h2 {
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fff;
    font-family: 'Muli', sans-serif;
    font-weight: 600;
}

.footer-title a {
    position: relative;
    z-index: 3;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    transition: all 200ms linear;
    color: #fff;
}

.footer-item {
    float: left;
    width: 100%;
    text-align: left;
    color: #fff;
}

.footer-item h4 {
    width: 100%;
    margin-bottom: 30px;
}

.footer-item p, .footer-item span, .footer-item ul li a {
    font-size: 11px;
    color: #fff;
}

.footer-item ul li {
    float: left;
    width: 100%;
    margin-bottom: 6px;
}

.footer-item span, .footer-item ul li a {
    text-transform: uppercase;
}

.footer-item span {
    letter-spacing: 1px;
}

.footer-item .text-link {
    color: #fff;
}

.footer-item .text-link:before {
    background: #fff;
}

.footer-logo img {
    margin-bottom: 20px;
}

.footer-logo p {
    max-width: 300px;
}

.footer-wrap {
    float: left;
    width: 100%;
    padding-top: 25px;
    margin-top: 15px;
    position: relative;
    border-top: 1px solid rgba(255, 255, 255, 0.21)
}

.footer-wrap:before {
    content: '';
    position: absolute;
    top: -3px;
    right: 22px;
    width: 6px;
    height: 6px;
    background: #fff;
    border-radius: 100%;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    transition: all 200ms linear;
}

.footer-wrap.tth:before {
    right: 0;
}

.copyright {
    float: left;
    text-align: left;
    color: #fff;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.to-top {
    float: right;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
    overflow: hidden;
    width: 70px;
    height: 40px;
    cursor: pointer;
    text-align: right;
}

/*------ map - ------------------------------------------------*/
.map-box {
    float: left;
    width: 100%;
    height: 400px;
    position: relative;
    margin: 40px 0;
}

#map-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
}

/*------ Contact  ------------------------------------------------*/
.contact-details {
    float: left;
    width: 100%;
    padding-top: 30px;
    text-align: left;
}

.contact-details h4 {
    margin-bottom: 10px;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
}

.contact-details ul {
    padding-bottom: 20px;
}

.contact-details ul li a {
    padding: 2px 0;
    float: left;
    font-size: 13px;
    width: 100%;
}

.contact-details ul li a:hover {
    text-decoration: underline;
}

.contact-form-holder {
    float: left;
    width: 100%;
    margin-top: 50px;
}

#contact-form, #comment-form {
    width: 100%;
    float: left;
    margin-top: 30px;
}

#contact-form input[type="text"],
#contact-form input[type="email"],
#contact-form textarea,
#comment-form input[type="text"],
#comment-form input[type="email"],
#comment-form textarea {
    float: left;
    width: 100%;
    background: none;
    margin-bottom: 20px;
    font-size: 12px;
    font-weight: 700;
    height: 50px;
    border-radius: 0;
    position: relative;
    z-index: 20;
    padding-left: 8px;
    color: #999;
    border: 0;
    border-bottom: 1px solid #000;
    -webkit-appearance: none;
}

#contact-form textarea, #comment-form textarea {
    height: 170px;
    resize: none;
    overflow: auto;
    background: none;
    box-shadow: none;
}

#submit, .form-submit button {
    float: left;
    border: 2px solid #000;
    background: none;
    padding: 15px 30px 15px 30px;
    line-height: 17px;
    cursor: pointer;
    font-weight: 800;
    font-size: 12px;
    color: #000;
    font-family: 'Muli', sans-serif;
    -webkit-appearance: none;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    transition: all 200ms linear;
    -webkit-appearance: none;
}

#submit:hover {
    color: #ccc;
}

#submit i, .to-top i, footer.fixed-footer .footer-social li a, .clients-list a {
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    transition: all 200ms linear;
}

#submit:hover i {
    color: #000;
}

.verify-wrap {
    float: left;
    width: 100%;
    margin: 15px 0;
}

.verify-text {
    float: left;
    margin-right: 20px;
    width: 100%;
    text-align: left;
}

#contact-form input#verify[type="text"] {
    float: left;
    width: 70px !important;
}

.error_message {
    text-align: left;
    font-size: 12px;
    max-width: 450px;
    position: relative;
    padding: 10px;
    color: #933;
    text-transform: uppercase;
}

#success_page h3 {
    text-align: left;
    font-size: 15px;
    text-transform: uppercase;
}

#success_page p {
    margin-top: 5px;
    text-align: left;
    padding: 6px 6px 6px 0;
    color: #000;
}

#success_page p strong {
    font-weight: bold;
}

#message fieldset {
    border: none;
}

/*------ facts  ------------------------------------------------*/
.sect-subtitle {
    position: absolute;
    bottom: 10%;
    color: rgba(0, 0, 0, 0.21);
    font-weight: bold;
    text-transform: uppercase;
    font-size: 185px;
    z-index: 2;
}

.sect-subtitle:before {
    content: '';
    position: absolute;
    top: 50%;
    width: 150px;
    height: 1px;
    background: rgba(0, 0, 0, 0.21);
}

.sect-subtitle:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 6px;
    height: 6px;
    background: #000;
    border-radius: 100%;
    margin-top: -3px;
    margin-left: -3px;
}

.dark-bg .sect-subtitle {
    color: rgba(255, 255, 255, 0.21);
}

.dark-bg .sect-subtitle:after {
    background: #fff;
}

.left-align-dec {
    left: 20%;
}

.right-align-dec {
    right: 10%;
}

.right-align-dec:before {
    right: 50%;
}

.left-align-dec:before {
    left: 50%;
}

.dark-bg .sect-subtitle:before {
    background: rgba(255, 255, 255, 0.351);
}

.inline-facts-holder:before {
    content: '';
    position: absolute;
    top: 50%;
    left: -50%;
    width: 20%;
    background: #fff;
    height: 6px;
    margin-top: -3px;
}

.inline-facts .title {
    float: right;
    width: 100%;
    font-size: 16px;
    margin-top: 10px;
    text-align: right;
    color: #fff;
    font-weight: 200;
}

.num {
    float: right;
    font-size: 82px;
    text-align: right;
    text-transform: uppercase;
    width: 100%;
    color: #fff;
    font-weight: bold;
}

/*------ process ------------------------------------------------*/
.process-box {
    float: left;
    width: 100%;
    margin-top: 60px;
    padding: 50px 0;
    position: relative;
}

.process-box:before {
    content: '';
    position: absolute;
    top: -20px;
    left: 0;
    width: 80px;
    height: 8px;
    background: #000;
}

.process-box h3, .bold-title {
    float: left;
    width: 100%;
    font-size: 26px;
    font-weight: 700;
    text-align: left;
    padding-bottom: 30px;
}

.creat-list {
    float: left;
    margin: 10px 0;
    width: 100%;
}

.creat-list li {
    float: left;
    margin-right: 10px;
}

.creat-list li a, .text-link {
    float: left;
    padding-left: 30px;
    font-size: 10px;
    font-family: 'Muli', sans-serif;
    line-height: 12px;
    text-transform: uppercase;
    padding-left: 63px;
    position: relative;
    font-weight: 800;
    letter-spacing: 2px;
    color: #292929;
}

.creat-list li a:before, .text-link:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 40px;
    height: 1px;
    background: #000;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    transition: all 200ms linear;
}

.creat-list li a:hover:before, .text-link:hover:before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* ------blog------------------------------------------------------------ */
.blg-nav {
    margin-top: -20px;
    margin-bottom: 20px;
}

.waimg {
    float: left;
    margin-bottom: 20px;
}

article {
    float: left;
    margin-bottom: 10px;
    width: 100%;
    position: relative;
    border-bottom: 1px solid #eee;
}

article .btn {
    float: left;
}

article .section-title {
    margin-bottom: 15px;
}

.post-author {
    margin-bottom: 40px;
    margin-top: 10px;
    float: left;
    border-bottom: 1px solid #eee;
    padding: 20px 0;
}

.author-img {
    float: left;
    width: 15%;
}

.author-content {
    float: left;
    width: 85%;
    padding-left: 10px;
}

.author-img img {
    border-radius: 100%;
    float: left;
}

.author-content h5 {
    font-size: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 14px;
    color: #000;
    display: block;
    float: left;
    font-weight: 500;
}

.author-content p {
    margin-bottom: 8px;
    float: left;
    width: 100%;
}

.widget {
    float: left;
    width: 100%;
    margin-bottom: 40px;
    text-align: left;
    border-bottom: 1px solid #000;
    padding-bottom: 20px;
}

.widget h3 {
    float: left;
    width: 100%;
    position: relative;
    font-size: 14px;
    text-transform: uppercase;
    color: #000;
    font-weight: 600;
    letter-spacing: 2px;
    padding-bottom: 20px;
}

.widget-posts li {
    padding: 12px 0;
}

.widget-posts-img {
    float: left;
    width: 30%;
}

.widget-comments-img img {
    border-radius: 100%;
}

.widget-posts-descr {
    float: left;
    width: 70%;
    padding-left: 10px;
}

.widget-posts-descr a {
    font-size: 10px;
    text-transform: uppercase;
    color: #000;
    letter-spacing: 3px;
    padding-bottom: 20px;
}

.widget-posts-date {
    float: left;
    width: 100%;
    margin-top: 10px;
    color: #999;
    font-size: 9px;
}

.searh-holder {
    max-width: 450px;
    margin-top: 20px;
    position: relative;
}

.searh-inner {
    float: left;
    width: 100%;
    position: relative;
}

.search {
    border: 1px solid rgba(0, 0, 0, .07);
    float: left;
    background: #fff;
    border-radius: 0;
    position: relative;
    outline: none;
    padding: 0 0 0 10px;
    width: 100%;
    height: 40px;
    color: #000;
    -webkit-appearance: none;
}

.search-submit {
    border: none;
    outline: none;
    cursor: pointer;
    background: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 28%;
    height: 40px;
}

.trnsp-search {
    border: 1px solid #fff;
    border-radius: 6px;
    color: #fff;
    background: rgba(255, 255, 255, 0.21);
}

.trnsp_btn {
    border-radius: 0 6px 6px 0;
}

.trnsp_btn i {
    color: #fff !important;
}

.search-submit:hover i {
    opacity: 0.5;
}

.tagcloud li {
    float: left;
    margin-bottom: 4px;
}

.tagcloud li a {
    float: left;
    padding: 6px 10px 6px 0;
    margin-right: 2px;
    color: #000;
    letter-spacing: 1px;
    font-weight: 600;
    text-transform: uppercase;
}

.tagcloud li a:hover {
    color: #ccc;
}

.cat-item {
    padding-bottom: 8px;
    margin-bottom: 15px;
}

.cat-item a {
    font-size: 14px;
    font-family: 'Muli', sans-serif;
    color: #000;
    text-transform: uppercase;
}

.lg-sub-html {
    font-size: 12px;
    font-family: 'Muli', sans-serif;
    color: #fff;
    text-transform: uppercase;
}

.cat-item a, .tagcloud li a {
    font-size: 10px;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    transition: all 200ms linear;
}

.sinnle-post h2 {
    float: left;
    font-size: 32px;
    text-align: left;
    width: 100%;
}

.blog-text {
    float: left;
    width: 100%;
    padding: 20px 0;
    margin-top: 10px;
}

.blog-text .btn {
    margin-top: 15px;
}

.blog-text h3, .comments-holder h3, .comment-form-holder h3 {
    font-size: 16px;
    text-transform: uppercase;
    text-align: left;
    font-weight: 400;
}

.blog-text h3 strong {
    font-weight: bold;
}

.blog-text {
    padding: 0;
    padding-bottom: 20px;
}

.blog-title a {
    color: #666;
}

.sinnle-post .blog-text {
    max-width: 900px;
}

.blog-media {
    float: left;
    width: 100%;
    position: relative;
    padding: 20px 0;
}

.blog-text p {
    font-size: 12px;
    padding-top: 10px;
}

.pagination-blog {
    margin-top: 20px;
    text-align: center;
    float: left;
    width: 100%;
    margin: 15px 0;
}

.pagination-blog ul {
    float: left;
    margin: 20px 0 30px;
}

.pagination-blog a {
    float: left;
    padding: 12px 18px;
    position: relative;
    font-size: 13px;
    color: #666;
    letter-spacing: 1px;
    margin-right: 6px;
    position: relative;

}

.pagination-blog a.current-page, .pagination-blog a:hover {
    color: #ccc;
}

.prevposts-link, .nextposts-link {
    position: relative;
    padding: 0;
    width: 50px;
    height: 40px;
    padding: 0 10px;
}

.prevposts-link:before, .nextposts-link:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background: #000;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    transition: all 200ms linear;
}

.prevposts-link:hover:before, .nextposts-link:hover:before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.sidebar {
    float: left;
    width: 100%;
    padding: 0 0 0 30px;
}

.sinnle-post p {
    font-size: 16px;
}

.sinnle-post .blog-text h3 {
    margin-bottom: 30px;
}

.comments-holder {
    float: left;
    width: 100%;
    position: relative;
    border-top: 1px solid #eee;
    padding-top: 30px;
    margin-top: 30px;
}

.commentlist {
    text-align: left;
    float: left;
    margin: 10px 0;
    max-width: 900px;
    padding: 20px 0;
    border-bottom: 1px solid #eee;
}

.comment-form-holder {
    max-width: 800px;
    float: left;
    margin-top: 30px;
}

#comments {
    text-align: left;
    padding-top: 10px;
    max-width: 800px;
}

#comments-title, #reply-title {
    border-bottom: 1px solid #eee;
    padding-bottom: 30px;
    margin-bottom: 5px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    color: #666;
}

.comment {
    float: left;
}

.comment-body {
    position: relative;
    margin-left: 70px;
    padding-top: 30px;
}

.comment-author {
    position: absolute;
    top: 30px;
    left: -66px;
}

.comment-author img {
    border-radius: 100%;
}

.comment .children {
    margin-left: 70px;
}

.fn {
    display: block;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 1px;
}

.comment-meta, .comment-meta a {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 10px;
    letter-spacing: 1px;
    color: #494949;
    padding-bottom: 10px;
}

#respond {
    margin-top: 40px;
    float: left;
    width: 100%;
}

#reply-title {
    padding-bottom: 30px;
    margin-bottom: 5px;
}

#commentform {
    float: left;
    width: 100%;
}

.comment-notes {
    margin-top: 10px;
    color: #a4a4a3;
}

.control-group label {
    margin-left: 10px;
    font-weight: 200;
    float: left;
    position: relative;
    top: 25px;
    font-weight: 600;
    letter-spacing: 2px;
}

.control-group .controls input {
    margin-top: 15px;
    border: none;
    outline: none;
    height: 35px;
    background: #fff;
    color: #a4a4a3;
    text-decoration: none;
    border-bottom: 1px solid #000;
    border-right: 2px solid #000;
    float: left;
    max-width: 600px;
}

.control-group .controls textarea {
    float: left;
    margin-top: 15px;
    border: none;
    outline: none;
    height: 180px;
    max-width: 600px;
    background: #fff;
    padding-left: 15px;
    padding-top: 15px;
    color: #a4a4a3;
    resize: vertical;
    text-decoration: none;
    border-bottom: 2px solid #000;
}

.controls button {
    margin-top: 30px;
    -webkit-appearance: none;
}

/*------ share------------------------------------------------*/
.show-share {
    position: absolute;
    top: 19px;
    right: 50px;
    z-index: 21;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.21);
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center !important;
    border: 1px solid rgba(255, 255, 255, 0.41);
}

.show-share span {
    color: #fff;
    position: absolute;
    left: -100%;
    margin-left: -15px;
    top: -15px;
    opacity: 0;
    line-height: 1;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: 'Muli', sans-serif;
    font-weight: 600;
    z-index: 2;
    text-decoration: line-through;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    transition: all 200ms linear;
}

.show-share:hover span {
    opacity: 1;
    top: 15px;
}

.show-share.isShare {
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.21);
}

.show-share.isShare span {
    text-decoration: none;
}

.share-inner {
    position: fixed;
    z-index: 20;
    right: 30%;
    top: 60px;
    visibility: hidden;
    width: 70%;
    background: #000;
    height: 0;
    overflow: hidden;
}

.hor-inner {
    width: 100%;
    right: 0;
}

.visshare {
    visibility: visible;
    height: 80px;
}

.share-container {
    position: relative;
    float: right;
    height: 100%;
    line-height: 100px;
    padding-right: 30px;
    opacity: 0;
}

.share-inner, .share-container, .show-share {
    -webkit-transition: all 400ms linear;
    -moz-transition: all 400ms linear;
    -o-transition: all 400ms linear;
    -ms-transition: all 400ms linear;
    transition: all 400ms linear;
}

.vissc {
    opacity: 1;
}

.share-icon {
    float: left;
    position: relative;
    margin-right: 20px;
    color: #fff;
}

.share-icon:hover {
    text-decoration: line-through;
}

.share-icon:before {
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fff;
    font-family: 'Muli', sans-serif;
    font-weight: 600;
    position: relative;
    z-index: 2;
    -webkit-transition: all 100ms linear;
    -moz-transition: all 100ms linear;
    -o-transition: all 100ms linear;
    -ms-transition: all 100ms linear;
    transition: all 100ms linear;
}

.share-icon-digg:before {
    content: "digg";
}

.share-icon-email:before {
    content: "email";
}

.share-icon-flickr:before {
    content: "flickr";
}

.share-icon-foursquare:before {
    content: "foursquare";
}

.share-icon-rss:before {
    content: "rss";
}

.share-icon-facebook:before {
    content: "facebook";
}

.share-icon-twitter:before {
    content: "twitter";
}

.share-icon-tumblr:before {
    content: "tumblr";
}

.share-icon-linkedin:before {
    content: "linkedin";
}

.share-icon-vimeo:before {
    content: "vimeo";
}

.share-icon-instagram:before {
    content: "instagram";
}

.share-icon-pinterest:before {
    content: "pinterest";
}

.share-icon-googleplus:before {
    content: "googleplus";
}

.share-icon-in1:before {
    content: "in1";
}

.share-icon-stumbleupon:before {
    content: "stumbleupon";
}

.transition {
    -webkit-transition: all 500ms linear;
    -moz-transition: all 500ms linear;
    -o-transition: all 500ms linear;
    -ms-transition: all 500ms linear;
    transition: all 500ms linear;
}

.transition2 {
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    transition: all 200ms linear;
}

.fl-l {
    float: left;
}

.nav-button-holder {
    display: none;
}

.hid-gal {
    overflow: hidden;
}

.align-items-end {
    align-items: end;
}

/*------  Responsive ------------------------------------------------------*/
@media only screen and (max-width: 1236px) {
    header {
        width: 90%;
    }
    .share-inner {
        width: 90%;
        right: 10%;
    }
    .creat-list li a {
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 1036px) {
    .hid-gal {
        overflow: auto;
    }
    .mob-bg {
        display: block;
    }
    header {
        width: 100%;
    }
    .nav-button-holder {
        position: absolute;
        right: 80px;
        top: 10px;
        display: block;
    }
    .show-share {
        right: 30px;
    }
    .nav-button {
        float: right;
        width: 40px;
        height: 40px;
        border: 1px solid rgba(255, 255, 255, 0.21);
        padding: 13px 6px 6px 6px;
        position: relative;
        margin-top: 9px;
        cursor: pointer;
    }
    .nav-button span {
        float: left;
        width: 100%;
        height: 2px;
        background: #fff;
        margin-bottom: 4px;
        position: relative;
    }
    .share-inner {
        width: 100%;
        right: 0;
    }
    .content-holder {
        padding: 0;
    }
    .header-inner {
        float: left;
        padding-left: 30px;
    }
    .inline-facts-holder:before, .show-share span, .fixed-footer, .height-emulator, .sect-subtitle, .content:before, .content-footer:before, nav ul li ul:before, nav li a.act-link:before, .control-panel, .close-sidebar, .fix-con-panel, .nicescroll-rails {
        display: none;
    }
    .particular {
        display: none !important;
    }
    .parallax-box {
        position: relative;
        left: 0;
        padding-top: 20px;
    }
    .parallax-box.r-align {
        left: 0;
    }
    .parallax-box img {
        opacity: 1;
    }
    .parallax-item {
        margin-bottom: 40px;
    }
    .parallax-deck {
        position: relative !important;
        bottom: 0;
        padding: 0;
        float: left;
        width: 100%;
    }
    .left-direction .parallax-deck {
        right: 0;
    }
    .right-direction .parallax-deck {
        left: 0;
    }
    .parallax-deck-item {
        float: left;
        max-width: 100%;
    }
    .paralax-wrap {
        float: left;
        width: 100%;
        opacity: 1;
        padding-top: 30px;
    }
    .parallax-deck h3 {
        font-size: 34px;
        line-height: 40px;
        padding-top: 15px;
        margin-bottom: 20px;
    }
    .algn-right {
        text-align: left;
    }
    .inline-facts .title {
        float: none;
        font-size: 16px;
        text-align: center;
    }
    .num, .inline-facts .title {
        float: left;
        text-align: left;
    }
    .nav-button-holder {
        display: block;
        right: 30px;
    }
    .nav-holder {
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        background: #000;
        padding: 30px 0 30px 30px;
        display: none;
        height: 300px;
        overflow: auto;
    }
    nav ul li, nav ul li ul li, nav li ul li > ul {
        float: left;
        width: 100%;
        text-align: left;
        left: 0;
        opacity: 1;
        right: 0;
        padding: 8px 0;
    }
    nav li ul {
        margin: 0;
        padding-left: 30px;
        padding-top: 20px;
        padding-bottom: 40px;
        opacity: 1;
        visibility: hidden;
        position: relative;
        width: 100%;
        display: none;
        top: 0;
        left: 0;
        z-index: 1;
    }
    nav li a, nav li ul li a {
        padding: 0;
        width: auto;
    }
    nav li ul li:before {
        content: '';
        position: absolute;
        top: 50%;
        left: -4px;
        width: 10px;
        height: 1px;
        background: rgba(255, 255, 255, 0.51);
    }
    nav li ul li a {
        color: rgba(255, 255, 255, 0.81);
        padding: 3px 15px;
    }
    nav li ul li ul {
        margin: 0;
        padding-left: 30px;
        padding-top: 5px;
        padding-bottom: 0;
        opacity: 1;
        visibility: hidden;
        position: relative;
        width: 100%;
        display: none;
        top: 0;
        left: 0;
        z-index: 1;

    }
    nav li:hover > ul, nav li ul li:hover > ul {
        display: block;
        left: 0;
    }
    nav li ul li:hover ul {
        display: block;
    }
    nav li a.act-link {
        opacity: 0.5
    }
    .content-footer {
        position: relative;
        float: left;
        padding: 50px 0;
    }
    .gallery-item {
        width: 50% !important;
    }

    .colum-w {
        padding-top: 0 !important
    }
    .custom-inner-holder .col-md-6 h3 {
        float: left;
        width: 100%;
        padding-bottom: 15px;
        text-align: left;
    }
    .footer-item {
        margin-bottom: 15px;
    }
    .contact-details h4 {
        margin-top: 20px;
        float: left;
        width: 100%;
    }
    .sidebar {
        padding-left: 0;
    }
    .hid-sidebar {
        position: relative;
        top: 0;
        left: 0;
        height: auto;
        float: left;
        width: 100%;
        display: block;
        background: #fff;
    }
    .horizontal_item img {
        width: 100%;
        height: auto !important;
        margin-bottom: 10px;
    }
    .sb-inner {
        opacity: 1;
        visibility: visible;
    }
    .sidebar-wrap {
        float: left;
        width: 100%;
        position: relative;
        padding: 50px 0;
        margin-bottom: 0;
    }
    .ogm {
        height: auto !important;
        padding: 0 6px;
        background: #fff;
    }
    .ogm .customNavigation {
        display: none;
    }
    .gallery_horizontal {
        padding-top: 6px;
    }
    .flow-gallery .horizontal_item {
        opacity: 1;
        -webkit-transform: scale(1.0);
        -moz-transform: scale(1.0);
        transform: scale(1.0);
    }
    section {
        padding: 50px 0;
    }
    .parallax-section {
        padding: 210px 0 150px;
    }
    .parallax-inner .bg {
        height: 100%;
        top: 0;
    }
    .smp {
        padding-left: 6px;
        padding-right: 6px;
    }

}

@media only screen and (max-width: 778px) {
    #portfolio_horizontal_container, .resize-carousel-holder {
        height: auto !important;
    }

    #portfolio_horizontal_container {
        padding-bottom: 60px;
    }
    #portfolio_horizontal_container .portfolio_item {
        width: 100% !important;
        height: auto !important;
        padding: 3px 6px;


    }
    .p_horizontal_wrap {
        -webkit-overflow-scrolling: touch;
    }

    #portfolio_horizontal_container .portfolio_item img {
        width: 100% !important;
        height: auto !important;
    }
    .hpw, .p_horizontal_wrap, .resize-carousel-holder {
        padding: 0;
    }
    .resize-carousel-holder {
        background: #fff;
    }
    .team-holder li {
        float: left;
        width: 50%;
    }
    .team-social li a {
        padding: 6px;
    }
    .port-desc-holder:before {
        top: 0;
        left: 3px;
        right: 5px;
        bottom: 3px;
    }
    .round-filter {
        bottom: 5px;
        width: auto;
        right: 6px;
        left: 6px;
    }
    .round-filter:before {
        display: none;
    }
    .gallery-filters a {
        width: 100%;
        text-align: left;
        padding-bottom: 10px;
    }
    .inline-filter .count-folio {
        width: 100%;
        float: left;
        margin-top: 30px;
    }
    .inline-filter .count-folio div {
        float: left;
    }
    .inline-filter {
        padding-bottom: 20px;
    }
    .mob-pa {
        padding: 0 5px 60px;
    }
    .ver-filter {
        bottom: 0;
        left: 2px;
        right: 2px;
    }
    .gallery-item {
        width: 100% !important;
        left: 0 !important
    }
    .smp {
        padding-left: 6px;
        padding-right: 6px;
    }
}

@media only screen and (max-width: 640px) {
    .num {
        font-size: 62px;
    }
    .page-title h2 {
        font-size: 40px;
    }
    .team-holder li {
        float: left;
        width: 100%;
        padding: 20px 0;
    }
    .grid-small-pad .grid-item-holder, .grid-big-pad .grid-item-holder {
        padding: 3px 0 !important;
    }
    .fixed-filter a {
        width: auto !important;
    }
    .gallery-filters a:before {
        height: 1px;
        width: 0;
    }
    .gallery-filters a.gallery-filter-active:before {
        width: 40px;
    }
    .smp {
        padding-left: 6px;
        padding-right: 6px;
    }
    .serv-item {
        width: 100%;
    }
    .clients-list a {
        width: 50%;
    }
    .section-title, .page-title h2, .sect-subtitle {
        font-size: 30px;
        line-height: 34px;
    }
    .parallax-deck-item h3 {
        font-size: 22px;
        line-height: 32px;
    }
    .hero-wrap-item h2 {
        font-size: 28px;
    }
    .hero-wrap-item h3, .hero-wrap-item h3 a {
        letter-spacing: 8px;
    }
    .customNavigation.fhsln a.next-slide {
        right: 0;
        top: 90%;
    }
    .customNavigation.fhsln a.prev-slide {
        left: 0;
        top: 90%;
    }
    p {
        font-size: 14px;
    }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) {
    .bg {
        background-attachment: scroll !important;
        -webkit-background-size: cover;
        background-position: center;
    }
}

@media screen and (max-width: 360px) {
    .copyright {
        font-size: 10px;
    }
}

@media screen and (max-width: 319px) {
    .copyright {
        font-size: 8px;
    }
}
